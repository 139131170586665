import { atom } from "recoil";
import { Scheduled } from '../../../../../createCampign.typs';

export type CampaignSettings ={
  accountsDomains: {domain: string, accounts: string[]}[];
  scheduledCampaign: Scheduled;
  isPausedCampaign: boolean;
  customDate?: {
    date: Date | null;
    hour: string;
    minutes: string;
  },
}

const initialState = {
  accountsDomains: [{ domain: '', accounts: [] }],
  scheduledCampaign: Scheduled.UploadNow,
  isPausedCampaign: false,
}

export const settingsState = atom<CampaignSettings>({
  key: 'SettingState',
  default: initialState
});



